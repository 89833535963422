<template>
  <div :class="{ 'dark-mode': $store.state.darkmode }" class="containter">
    <Header @click="$router.push('/' + $store.state.language)"></Header>
    <div class="content">
      <h1>
        Informácie týkajúce sa spracúvania a ochrany osobných údajov zákazníkov
      </h1>
      <p>
        Plnenie informačnej povinnosti pred spracovávaním osobných údajov v
        zmysle článku 13 GDPR.
      </p>

      <h2>Legislatíva</h2>
      <ul>
        <li>
          Zákon č. 18/2018 o ochrane osobných údajov a o zmene a doplnení
          niektorých zákonov
        </li>
        <li>
          Nariadenie Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla
          2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o
          voľnom pohybe takýchto údajov
        </li>
      </ul>

      <h2>Identifikácia správcu osobných údajov</h2>
      <p>
        Prevádzkovateľom portálu
        <span class="highlight">www.hsbuilding.eu</span> je spoločnosť H&S
        Building s. r. o., IČO: 54 792 011, so sídlom Párnica 323, 026 01
        Párnica (ďalej len “my” alebo “správca”), ktorá vaše osobné údaje
        spracováva ako správca, tj. určuje, ako budú osobné údaje spracovávané,
        za akým účelom a ako dlho.
      </p>

      <h2>Práva dotknutých osôb</h2>
      <p>
        Podľa čl. 15 až 22 GDPR a § 19 až 29 zákona o ochrane osobných údajov sa
        uplatňujú nasledovné práva dotknutých osôb:
      </p>

      <h3>Právo na prístup k osobným údajom</h3>
      <p>
        Každá dotknutá osoba má právo, aby správca potvrdil, či spracúva osobné
        údaje, ktoré sa jej týkajú. Ak správca spracúva osobné údaje dotknutej
        osoby, má dotknutá osoba právo získať k nim prístup a ďalšie informácie
        o:
      </p>
      <ul>
        <li>účele spracúvania osobných údajov;</li>
        <li>kategórii spracúvaných osobných údajov;</li>
        <li>
          príjemcovi v tretej krajine alebo o medzinárodnej organizácii, ak je
          to možné; ak sa osobné údaje prenášajú do tretej krajiny alebo
          medzinárodnej organizácii;
        </li>
        <li>
          dobe uchovávania osobných údajov; ak to nie je možné, informáciu o
          kritériách jej určenia;
        </li>
        <li>
          práve požadovať opravu osobných údajov, ich vymazanie alebo obmedzenie
          ich spracúvania, alebo o práve namietať spracúvanie osobných údajov;
        </li>
        <li>
          práve podať návrh na začatie konania podľa § 100 zákona o ochrane
          osobných údajov alebo podať sťažnosť podľa čl. 15 písm. f) GDPR;
        </li>
        <li>
          zdroji osobných údajov, ak sa osobné údaje nezískali od dotknutej
          osoby;
        </li>
        <li>
          existencii automatizovaného individuálneho rozhodovania vrátane
          profilovania prenosu osobných údajov do tretích krajín alebo do
          medzinárodnej organizácií a o primeraných zárukách podľa čl. 46 GDPR
          alebo § 48 zákona o ochrane osobných údajov.
        </li>
      </ul>
      <p>
        Správca je povinný poskytnúť dotknutej osobe kópie jej osobných údajov,
        ktoré spracúva. Za opakované poskytnutie kópií osobných údajov môže
        správca účtovať primeraný poplatok zodpovedajúci administratívnym
        nákladom. Správca je povinný poskytnúť osobné údaje spôsobom, akým sú
        požadované.
      </p>

      <h3>Právo na opravu osobných údajov</h3>
      <p>
        Dotknutá osoba má právo na to, aby správca bez zbytočného odkladu
        opravil nesprávne osobné údaje, ktoré sa jej týkajú. V závislosti od
        účelu spracúvania osobných údajov má dotknutá osoba právo na doplnenie
        jej neúplných osobných údajov.
      </p>

      <h3>Právo na výmaz osobných údajov</h3>
      <p>
        Dotknutá osoba má právo na to, aby správca bez zbytočného odkladu
        vymazal osobné údaje, ktoré sa jej týkajú, za podmienok ustanovených v
        čl. 17 GDPR alebo § 23 zákona o ochrane osobných údajov.
      </p>
      <p>
        Ak dotknutá osoba požiada správcu o vymazanie jej osobných údajov,
        správca je povinný ich vymazať v týchto prípadoch:
      </p>
      <ul>
        <li>
          osobné údaje už nie sú potrebné na účel, na ktorý sa získali alebo
          inak spracúvali;
        </li>
        <li>
          dotknutá osoba odvolá súhlas, na základe ktorého správca jej osobné
          údaje spracúva a neexistuje iný právny základ pre spracúvanie osobných
          údajov;
        </li>
        <li>
          dotknutá osoba bude namietať spracúvanie osobných údajov podľa čl. 21
          ods. 1 GDPR alebo § 27 ods. 1 zákona o ochrane osobných údajov a
          neprevažujú žiadne oprávnené dôvody na spracúvanie osobných údajov
          alebo bude namietať spracúvanie osobných údajov na účel priameho
          marketingu vrátane profilovania v rozsahu v akom súvisí s priamym
          marketingom;
        </li>
        <li>osobné údaje sa spracúvajú nezákonne;</li>
        <li>
          dôvodom pre výmaz je splnenie povinnosti stanovenej právnym poriadkom;
        </li>
        <li>
          osobné údaje sa získavali v súvislosti s ponukou služieb informačnej
          spoločnosti podľa čl. 8 ods. 1 GDPR alebo § 15 ods. 1 zákona o ochrane
          osobných údajov.
        </li>
      </ul>
      <p>
        Ak správca zverejnil osobné údaje dotknutej osoby a je povinný ich na
        základe vyššie uvedených podmienok vymazať, má zároveň povinnosť
        vzhľadom na dostupnú technológiu a náklady informovať ostatných
        prevádzkovateľov, ktorí spracúvajú jej osobné údaje, aby títo
        prevádzkovatelia vymazali odkazy na osobné údaje a ich kópie alebo
        odpisy.
      </p>
      <p>
        Správca nemá povinnosť zmazať osobné údaje dotknutej osoby, ak sú
        potrebné:
      </p>
      <ul>
        <li>
          na uplatnenie práva na slobodu prejavu alebo práva na informácie;
        </li>
        <li>
          na splnenie povinnosti podľa zákona alebo medzinárodnej zmluvy alebo
          na splnenie úlohy vo verejnom záujme alebo pri výkone verejnej moci,
          ktorým je poverený správca;
        </li>
        <li>
          z dôvodov verejného záujmu v oblasti verejného zdravia podľa čl. 9
          ods. 2 písm. h) a i) GDPR a § 16 ods. 2 písm. h) a i) zákona o ochrane
          osobných údajov;
        </li>
        <li>
          na účely archivácie vo verejnom záujme, na účely vedeckého alebo
          historického výskumu alebo na štatistické účely podľa čl. 89 ods. 1
          GDPR alebo § 78 ods. 8 zákona o ochrane osobných údajov;
        </li>
        <li>na uplatnenie právnych nárokov.</li>
      </ul>

      <h3>Právo na obmedzenie spracúvania osobných údajov</h3>
      <p>
        Dotknutá osoba má právo na to, aby správca obmedzil spracúvanie jej
        osobných údajov, ak:
      </p>
      <ul>
        <li>
          dotknutá osoba napadne správnosť osobných údajov, a to počas obdobia
          umožňujúceho správcovi overiť správnosť osobných údajov;
        </li>
        <li>
          spracúvanie osobných údajov je nezákonné a dotknutá osoba namiesto
          vymazania osobných údajov žiada obmedzenie ich použitia;
        </li>
        <li>
          správca už nepotrebuje osobné údaje na účely spracúvania osobných
          údajov, ale potrebuje ich dotknutá osoba na uplatnenie právneho
          nároku;
        </li>
        <li>
          dotknutá osoba namieta spracúvanie osobných údajov podľa čl. 21 ods. 1
          GDPR alebo § 27 ods. 1 zákona o ochrane osobných údajov, a to až do
          overenia, či oprávnené dôvody na strane správcu prevažujú nad
          oprávnenými dôvodmi dotknutej osoby.
        </li>
      </ul>
      <p>
        Ak sa spracúvanie osobných údajov obmedzilo, takto označené osobné údaje
        sa môžu spracúvať, s výnimkou ich uchovávania, len so súhlasom dotknutej
        osoby alebo na uplatnenie právneho nároku, na ochranu práv inej fyzickej
        osoby alebo právnickej osoby alebo z dôvodu verejného záujmu. Správca je
        povinný informovať dotknutú osobu pred tým, ako bude obmedzenie
        spracúvania osobných údajov zrušené.
      </p>

      <h3>Právo na prenosnosť osobných údajov</h3>
      <p>
        Dotknutá osoba má právo získať osobné údaje, ktoré sa jej týkajú a ktoré
        poskytla správcovi, v štruktúrovanom, bežne používanom a strojovo
        čitateľnom formáte. Zároveň má právo preniesť tieto osobné údaje
        ďalšiemu správcovi, ak je to technicky možné, a ak:
      </p>
      <ul>
        <li>
          sa osobné údaje spracúvajú na základe súhlasu dotknutej osoby alebo na
          základe zmluvy;
        </li>
        <li>
          sa spracúvanie osobných údajov vykonáva automatizovanými
          prostriedkami.
        </li>
      </ul>
      <p>
        Právo na prenos osobných údajov nesmie mať nepriaznivé dôsledky na práva
        iných osôb.
      </p>

      <h3>Právo namietať spracúvanie osobných údajov</h3>
      <p>
        Dotknutá osoba má právo kedykoľvek namietať spracúvanie jej osobných
        údajov z dôvodov týkajúcich sa jej konkrétnej situácie vykonávané na
        základe čl. 6 ods. 1 písm. e) alebo f) GDPR vrátane profilovania
        založeného na týchto ustanoveniach. Správca nesmie ďalej spracúvať
        osobné údaje, ak nepreukáže nevyhnutné oprávnené dôvody na spracúvanie
        osobných údajov, ktoré prevažujú nad právami alebo záujmami dotknutej
        osoby alebo dôvody na uplatnenie právneho nároku.
      </p>
      <p>
        Ak sa osobné údaje spracúvajú na účel priameho marketingu, dotknutá
        osoba má právo kedykoľvek namietať spracúvanie osobných údajov, ktoré sa
        jej týkajú, na účel takéhoto marketingu vrátane profilovania v rozsahu,
        v akom súvisí s priamym marketingom. Ak dotknutá osoba namieta
        spracúvanie osobných údajov na účel priameho marketingu, správca nesmie
        ďalej spracúvať osobné údaje na účel priameho marketingu.
      </p>

      <h3>Automatizované individuálne rozhodovanie vrátane profilovania</h3>
      <p>
        Dotknutá osoba má právo na to, aby sa na ňu nevzťahovalo rozhodnutie,
        ktoré je založené výlučne na automatizovanom spracúvaní osobných údajov
        vrátane profilovania a ktoré má právne účinky, ktoré sa jej týkajú alebo
        ju podobne významne ovplyvňujú. To neplatí, ak je rozhodnutie:
      </p>
      <ul>
        <li>
          nevyhnutné na uzavretie zmluvy alebo plnenie zmluvy medzi dotknutou
          osobou a správcom;
        </li>
        <li>
          povolené právom Únie alebo právom členského štátu, ktorému podlieha
          správca, a ktoré zároveň ustanovuje vhodné opatrenia zaručujúce
          ochranu práv a slobôd dotknutej osoby a oprávnených záujmov;
        </li>
        <li>založené na výslovnom súhlase dotknutej osoby.</li>
      </ul>
      <p>
        V prípadoch podľa prvého a tretieho bodu je správca povinný prijať
        vhodné opatrenia na ochranu práv a slobôd dotknutej osoby a oprávnených
        záujmov, najmenej právo na ľudský zásah zo strany správcu, právo
        vyjadriť svoje stanovisko a právo napadnúť rozhodnutie.
      </p>

      <h2>Podmienky poskytovania súhlasu</h2>
      <p>
        Ak sa spracúvanie osobných údajov zakladá na súhlase dotknutej osoby,
        správca musí vedieť preukázať, že dotknutá osoba vyjadrila súhlas so
        spracúvaním jej osobných údajov. Dotknutá osoba má právo kedykoľvek svoj
        súhlas odvolať. Súhlas musí byť poskytnutý v zrozumiteľnej a ľahko
        dostupnej forme a musí byť možné ho kedykoľvek odvolať.
      </p>

      <h2>Ochrana práv dotknutých osôb</h2>
      <p>
        Správca prijíma všetky primerané opatrenia, aby zabezpečil ochranu práv
        dotknutých osôb. Správca je povinný bez zbytočného odkladu, najneskôr do
        jedného mesiaca od doručenia žiadosti, informovať dotknutú osobu o
        vybavení jej žiadosti týkajúcej sa jej práv.
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/navigation/Header.vue";
import Footer from "@/components/navigation/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";

h1 {
  color: $tx-clr-light;
  margin-top: 6em;
  text-align: center;
}
h2 {
  color: darken($color: $tx-clr-light, $amount: 10%);
  margin-top: 1em;
  border-bottom: 2px solid $dt-clr-light;
  padding-bottom: 5px;
}
h3 {
  color: darken($color: $tx-clr-light, $amount: 10%);
  margin-top: 1.3em;
  font-size: 1.4em;
}
p,
ul {
  margin: 10px 0;
}
ul {
  padding-left: 20px;
}
li {
  margin-bottom: 5px;
}

p,
ul,
li {
  text-align: left;
}
.containter {
  background-color: $bg-clr-light;
  box-shadow: 0 0 10px rgba($color: $bg-clr-light, $alpha: 0.1);
}

.content {
  width: 90%;
  margin: 5em auto;
  text-align: left;
}
.highlight {
  background-color: $bg-clr-light;
  padding: 2px 4px;
  border-radius: 4px;
}

li:hover {
  text-decoration: none;
  transform: none;
  cursor: default;
}

.dark-mode {
  h1 {
    color: $tx-clr-dark;
  }

  h2 {
    color: darken($color: $tx-clr-dark, $amount: 10%);
    border-bottom: 2px solid $dt-clr-dark;
  }

  h3 {
    color: darken($color: $tx-clr-dark, $amount: 10%);
  }

  background-color: $bg-clr-dark;
  box-shadow: 0 0 10px rgba($color: $bg-clr-dark, $alpha: 0.1);

  .highlight {
    background-color: $bg-clr-dark;
  }

  p,
  ul,
  li {
    color: $tx-clr-dark;
  }
}
</style>
