<template>
  <section :class="{ 'dark-mode': $store.state.darkmode }">
    <h2>{{ texts[$store.state.language].services.title }}</h2>
    <div class="line notoppadding"></div>

    <div class="service">
      <div class="box">
        <img
          src="@/assets/images/sluzby1.svg"
          :alt="texts[$store.state.language].services.service1Alt"
        />
        <p class="popis" :style="{ fontSize: parentFontSize + 'em' }">
          {{ texts[$store.state.language].services.service1Desc }}
        </p>
      </div>
      <div class="box">
        <img
          src="@/assets/images/sluzby2.svg"
          :alt="texts[$store.state.language].services.service2Alt"
        />
        <p class="popis" :style="{ fontSize: parentFontSize + 'em' }">
          {{ texts[$store.state.language].services.service2Desc }}
        </p>
      </div>
      <div class="box">
        <img
          src="@/assets/images/sluzby3.svg"
          :alt="texts[$store.state.language].services.service3Alt"
        />
        <p class="popis" :style="{ fontSize: parentFontSize + 0.2 + 'em' }">
          {{ texts[$store.state.language].services.service3Desc }}
        </p>
      </div>
      <div class="box">
        <img
          src="@/assets/images/sluzby4.svg"
          :alt="texts[$store.state.language].services.service4Alt"
        />
        <p class="popis" :style="{ fontSize: parentFontSize + 0.2 + 'em' }">
          {{ texts[$store.state.language].services.service4Desc }}
        </p>
      </div>
    </div>

    <div class="line"></div>
  </section>
</template>

<script>
import { texts } from "@/assets/js/texts.js";
export default {
  data() {
    return {
      texts: texts,
    };
  },
  props: {
    parentFontSize: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";
//lightmode
img {
  filter: invert(1);
}

.line {
  border: 1px solid $dt1-clr-light;
}

.service {
  background-color: $bg-sc-clr-light;
}

.box {
  background: $bg-clr-light;
}

p,
h2 {
  color: $tx-clr-light;
}

section {
  background-color: $bg-clr-light;
}

//darkmode
.dark-mode img {
  filter: invert(0);
}

.dark-mode .line {
  border: 1px solid $dt1-clr-dark;
}

.dark-mode .service {
  background-color: $bg-sc-clr-dark;
}

.dark-mode .box {
  background: $bg-clr-dark;
}

.dark-mode p,
.dark-mode h2 {
  color: $tx-clr-dark;
}

.dark-mode {
  background-color: $bg-clr-dark;
}

//scss

h2 {
  text-align: center;
}

.line {
  content: "";
  max-width: 20em;
  margin: 2em auto 4em;

  &.notoppadding {
    margin-top: 0;
  }
}

.service {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3em;

  padding-bottom: 2em;
}

.box {
  padding: 2em;
  border-bottom-right-radius: 2em;
  width: 15%;
  max-width: 10em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  min-height: 11em;

  img {
    width: 60%;
    height: 5.2em;
    object-fit: contain;
  }

  .popis {
    text-transform: uppercase;
    font-size: 1.1em;
    padding-top: 1em;
  }
}

@media only screen and (max-width: 1100px) {
  section {
    font-size: 90%;
  }
  .service {
    gap: 3%;
  }
}

@media only screen and (max-width: 900px) {
  section {
    font-size: 80%;
  }

  .service {
    padding: 0 5% 2em;
  }
}

@media only screen and (max-width: 750px) {
  .service {
    padding: 0 5% 2em;

    flex-wrap: wrap;
    .box {
      margin: 1em 10%;
    }
  }

  .line {
    margin: 1em auto 2em;
  }

  .box {
    min-width: 9em;
    margin: 0.5em 0;
  }

  .service .box:first-child {
    margin-top: -1em;
  }

  .service .box:nth-child(2) {
    margin-top: -1em;
  }
}

@media only screen and (max-width: 670px) {
  .service .box {
    margin: 1em 5%;
  }
}

@media only screen and (max-width: 500px) {
  .service .box {
    margin: 1em auto;

    img {
      width: 70%;
    }
  }

  section {
    font-size: 70%;
  }
}

@media only screen and (max-width: 400px) {
  .box {
    font-size: 90%;
  }
}
</style>
