// store.js
import { createStore } from "vuex";

export default createStore({
  state: {
    darkmode: true,
    darkmodeManual: false,
    language: "",
  },
  mutations: {
    toggleDarkMode(state) {
      state.darkmode = !state.darkmode;
      state.darkmodeManual = true;
    },
    setLanguage(state, language) {
      state.language = language;
    },
  },
  actions: {
    zmenaJazyka(store) {
      const currentPath = window.location.pathname;

      if (currentPath.includes("/sk")) {
        store.state.language = "sk";
      } else if (currentPath.includes("/de")) {
        store.state.language = "de";
      } else {
        store.state.language = "";
      }
    },
  },
});
