<template>
  <div v-if="isLoaded" class="container">
    <TheMenu />
    <router-view />
  </div>

  <TheLoader v-else />
</template>

<script>
import TheLoader from "@/components/functionality/TheLoader.vue";

export default {
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isLoaded = true;
        this.$store.dispatch("zmenaJazyka");
      }
    };

    if (!this.$store.state.darkmodeManual) {
      const time = new Date().getHours();
      if (time >= 18 || time <= 8) {
        this.$store.state.darkmode = true;
        console.log("time :>> ", time);
      } else {
        this.$store.state.darkmode = false;
        console.log("time :>> ", time);
      }
    }
  },
  components: {
    TheLoader,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lalezar&display=swap");
@import "@/assets/sass/_defaultStyles.scss";
@import "@/assets/sass/globalStyles.scss";
@import "@/assets/sass/_colors.scss";

#app {
  //font-family: Lalezar, Helvetica, Arial, Kanit, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
