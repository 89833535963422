<template>
  <section :class="{ 'dark-mode': $store.state.darkmode }">
    <div class="box">
      <div class="image">
        <img
          src="@/assets/images/aboutUs.png"
          :alt="texts[$store.state.language].about.imageAlt"
        />
      </div>

      <div class="text">
        <h2>{{ texts[$store.state.language].about.title }}</h2>

        <div class="line"></div>

        <p>
          {{ texts[$store.state.language].about.paragraph1 }}
          <span>{{ texts[$store.state.language].about.paragraph1span }} </span>
          {{ texts[$store.state.language].about.paragraph1con }}
        </p>

        <p>{{ texts[$store.state.language].about.paragraph2 }}</p>

        <p>
          {{ texts[$store.state.language].about.paragraph3 }}
          <span>{{ texts[$store.state.language].about.paragraph3span }}</span>
          {{ texts[$store.state.language].about.paragraph3con }}
        </p>

        <div class="line small"></div>
      </div>
    </div>
    <div class="logo">
      <img
        src="@/assets/images/logo.png"
        :alt="texts[$store.state.language].about.logoAlt"
      />
      <p class="lalezar">{{ texts[$store.state.language].about.company }}</p>
    </div>
  </section>
</template>

<script>
import { texts } from "@/assets/js/texts.js";
export default {
  data() {
    return {
      texts: texts,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";

//lightmode
.box .image {
  border: 0.1em solid $dt1-clr-light;
}

.line {
  border: 1px solid $dt1-clr-light;
}

.logo img {
  filter: invert(1);
}

// .logo img {
//   border: 0.1em solid $bg-sc-clr-light;
// }

p,
h2 {
  color: $tx-clr-light;
}

section {
  background-color: $bg-clr-light;
}

//darkmode
.dark-mode .box .image {
  border: 0.3em solid $dt1-clr-dark;
}

.dark-mode .line {
  border: 1px solid $dt1-clr-dark;
}

.dark-mode .logo img {
  filter: invert(0);
}

// .dark-mode .logo img {
//   border: 0.1em solid $bg-sc-clr-dark;
// }

.dark-mode p,
.dark-mode h2 {
  color: $tx-clr-dark;
}

.dark-mode {
  background-color: $bg-clr-dark;
}

//scss
.box {
  display: flex;
  align-items: center;
  gap: 12%;
  width: 100%;
  padding: 4em 11% 0;
  box-sizing: border-box;

  p {
    text-align: left;
  }

  .image {
    width: auto;
    border-top-right-radius: 2.5em;
    padding: 0.6em;
  }

  img {
    width: 100%;
    border-top-right-radius: 2.5em;
  }
}

.text {
  width: 60%;

  p {
    margin: 1.4em 0 1.4em;
    font-size: 1.35em;
  }
}

span {
  font-weight: 700;
}

.line {
  content: "";
  width: 60%;
}

.line.small {
  width: 40%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;

  padding: 1em 20% 5em;
}

.logo img {
  width: 4em;
}

.logo p {
  font-size: 1.3em;
  margin-bottom: -0.4em;
}

@media only screen and (max-width: 1300px) {
  .box {
    padding: 4em 5% 0;
  }
}

@media only screen and (max-width: 900px) {
  .box {
    flex-direction: column;

    .image {
      margin-bottom: 3em;
      width: 70%;
    }

    .text {
      width: 90%;
      max-width: 28em;
    }
  }
}

@media only screen and (max-width: 500px) {
  .box,
  .logo {
    font-size: 80%;

    .image {
      margin-bottom: 1em;
    }
  }

  .line {
    width: 80;
  }

  .text p {
    margin: 1em 0;
  }

  .line.small {
    width: 60%;
  }

  .logo {
    padding: 1em 10% 5em;
  }
}
</style>
