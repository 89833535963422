<template>
  <header
    v-if="texts[$store.state.language]?.header != undefined"
    :class="{
      'dark-mode': $store.state.darkmode,
      scrolling: changeColor,
    }"
  >
    <div @click="$emit('scroll-to-section', 'hero')" class="logo">
      <img src="@/assets/images/logo.png" alt="Logo H&S Building" />
      <h5>H&S Building</h5>
    </div>

    <div :style="{ fontSize: menuSize + '%' }" class="navigation">
      <ul>
        <li @click="$emit('scroll-to-section', 'about')">
          {{ texts[$store.state.language].header.oNas }}
        </li>
        <li @click="$emit('scroll-to-section', 'services')">
          {{ texts[$store.state.language].header.sluzby }}
        </li>
        <li @click="$emit('scroll-to-section', 'references')">
          {{ texts[$store.state.language].header.referencie }}
        </li>
        <li @click="$emit('scroll-to-section', 'career')">
          {{ texts[$store.state.language].header.kariera }}
        </li>
        <li @click="$emit('scroll-to-section', 'contact')">
          {{ texts[$store.state.language].header.kontakt }}
        </li>
      </ul>
    </div>

    <div class="hamburger" @click="toggleMenu">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>

    <div
      @mouseleave="toggleMenu"
      class="menu-mobile"
      :class="{ open: isMenuOpen }"
      v-if="isMenuOpen"
    >
      <ul>
        <li
          @click="
            $emit('scroll-to-section', 'about');
            toggleMenu();
          "
        >
          {{ texts[$store.state.language].header.oNas }}
        </li>
        <li
          @click="
            $emit('scroll-to-section', 'services');
            toggleMenu();
          "
        >
          {{ texts[$store.state.language].header.sluzby }}
        </li>
        <li
          @click="
            $emit('scroll-to-section', 'references');
            toggleMenu();
          "
        >
          {{ texts[$store.state.language].header.referencie }}
        </li>
        <li
          @click="
            $emit('scroll-to-section', 'career');
            toggleMenu();
          "
        >
          {{ texts[$store.state.language].header.kariera }}
        </li>
        <li
          @click="
            $emit('scroll-to-section', 'contact');
            toggleMenu();
          "
        >
          {{ texts[$store.state.language].header.kontakt }}
        </li>
      </ul>
    </div>

    <div class="control">
      <div @click="toggleWindow" class="box">
        <p class="language">
          {{ texts[$store.state.language].header.jazykSkratka }}
        </p>
        <img src="@/assets/images/arrow.svg" alt="" class="arrow" />
      </div>

      <div
        v-if="isWindowVisible"
        @mouseleave="toggleWindow"
        class="interactive-window"
        @click="this.$store.dispatch('zmenaJazyka')"
      >
        <h3>{{ texts[$store.state.language].header.jazyk }}</h3>
        <div class="flags">
          <img
            @click="
              $router.push('/de/');
              this.$store.dispatch('zmenaJazyka');
            "
            src="@/assets/images/germany.png"
            alt="Nemecký jazyk"
          />
          <img
            @click="
              $router.push('/sk/');
              this.$store.dispatch('zmenaJazyka');
            "
            src="@/assets/images/slovakia.png"
            alt="Slovenský jazyk"
          />
        </div>

        <h3>{{ texts[$store.state.language].header.nocnyRezim }}</h3>
        <div class="toggle-switch">
          <input
            v-model="this.$store.state.darkmode"
            type="checkbox"
            id="toggle"
            @click="toggleTheme"
          />
          <label for="toggle"></label>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { texts } from "@/assets/js/texts.js";

export default {
  data() {
    return {
      isWindowVisible: false,
      isMenuOpen: false,
      texts: texts,
      changeColor: false,
    };
  },
  props: {
    menuSize: {
      type: Number,
    },
  },
  methods: {
    toggleWindow() {
      this.isWindowVisible = !this.isWindowVisible;
      this.isMenuOpen = false;
    },
    toggleTheme() {
      this.$store.commit("toggleDarkMode");
      this.isMenuOpen = false;
      this.isWindowVisible = false;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.isWindowVisible = false;

      // if (this.isMenuOpen) {
      //   window.addEventListener("click", this.closeAll);
      // } else {
      //   window.removeEventListener("click", this.closeAll);
      // }
    },
    closeAll() {
      this.isMenuOpen = false;
      this.isWindowVisible = false;
    },
    handleScroll() {
      // Získanie aktuálnej pozície scrollu
      const scrollPosition = window.scrollY || window.pageYOffset;

      // Podmienka na zmenu farby pozadia podľa pozície scrollu
      if (scrollPosition > 200 || window.scrollY > 200) {
        this.changeColor = true; // Použitie tmavšej farby
      } else {
        this.changeColor = false; // Použitie predvolenej farby
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";

//light mode
.interactive-window {
  background: darken($color: $bg-clr-light, $amount: 3%);
  border: 1px solid $dt1-clr-light;
  box-shadow: 0 4px 8px rgba($color: $tx-clr-light, $alpha: 0.1);
}

.scrolling {
  background: rgba($color: $bg-clr-light, $alpha: 0.8);

  h5 {
    margin: 1em 0 1em 0.5em;
  }
}

h3 {
  color: $tx-clr-light;
}

.control .box {
  border: 0.18em solid $tx-clr-light;
}

.hamburger .line {
  background-color: $tx-clr-light;
}

.toggle-switch label::after {
  background: lighten($color: $tx-clr-light, $amount: 3%);
}

.toggle-switch label {
  background: lighten($color: $tx-clr-light, $amount: 70%);
}

.toggle-switch input:checked + label {
  background: lighten($color: $tx-clr-light, $amount: 70%);
}

.menu-mobile {
  border: 0.13em solid $dt1-clr-light;
}

.navigation li {
  color: $tx-clr-light;
}

.language {
  color: $tx-clr-light;
}

.dark-mode h5 {
  color: $tx-clr-light;
}

.menu-mobile.open {
  background: darken($bg-clr-light, 10%);

  li {
    color: $tx-clr-light;
  }
  li:not(:last-child) {
    border-bottom: 0.1em solid darken($bg-clr-light, 30%);
  }
}

//darkmode
.dark-mode .interactive-window {
  background: lighten($color: $bg-clr-dark, $amount: 10%);
  border: 1px solid $dt1-clr-dark;
  box-shadow: 0 4px 8px rgba($color: $tx-clr-dark, $alpha: 0.1);
}

.dark-mode h3 {
  color: $tx-clr-dark;
}

.dark-mode .control .box {
  border: 0.18em solid $tx-clr-dark;
}

.dark-mode .hamburger .line {
  background-color: $tx-clr-dark;
}

.dark-mode .toggle-switch label::after {
  background: darken($color: $tx-clr-dark, $amount: 70%);
}

.dark-mode .toggle-switch label {
  background: darken($color: $tx-clr-dark, $amount: 70%);
}

.dark-mode .toggle-switch input:checked + label {
  background: darken($color: $tx-clr-dark, $amount: 10%);
}

.dark-mode .menu-mobile {
  border: 0.13em solid $dt1-clr-dark;
}

.dark-mode .navigation li {
  color: $tx-clr-dark;
}

.dark-mode .language {
  color: $tx-clr-dark;
}

.dark-mode .arrow {
  filter: invert(1);
}

.dark-mode h5 {
  color: $tx-clr-dark;
}

.dark-mode .logo img {
  filter: invert(0);
}

.dark-mode .menu-mobile.open {
  background: lighten($bg-clr-dark, 10%);

  li {
    color: $tx-clr-dark;
  }
  li:not(:last-child) {
    border-bottom: 0.1em solid lighten($bg-clr-dark, 30%);
  }
}

.dark-mode.scrolling {
  background: rgba($color: $bg-clr-dark, $alpha: 0.9);

  h5 {
    margin: 1em 0 1em 0.5em;
  }
}

//scss
header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  transition: background-color 0.3s ease; /* Plynulý prechod farieb */

  .logo {
    width: 30%;
  }

  .navigation {
    width: 55%;
  }

  .control {
    width: 15%;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;

  h5 {
    margin-left: 0.5em;
    font-size: 1.6em;
    font-weight: 500;
  }

  img {
    filter: invert(37%) sepia(97%) saturate(5436%) hue-rotate(210deg)
      brightness(99%) contrast(50%);
    filter: invert(1);
    width: 6em;
    // margin-top: -1em;
  }
}

.navigation {
  ul {
    padding-left: 0;
    list-style-type: none;
    padding: 0;
    list-style-type: none;
    display: flex;
    gap: 8%;
    align-items: center;
    justify-content: flex-end;
  }

  li {
    font-size: 1.2em;
    width: auto;
    white-space: nowrap;
  }
}

.control {
  position: relative;
  display: flex;
  justify-content: center;

  h3 {
    margin-bottom: 0;
  }

  .box {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 0.5em 0.3em 0.5em 0.2em;
    width: min-content;
    border-bottom-right-radius: 1rem;
  }
}

.flags {
  margin: 0 10%;
  width: 80%;

  img {
    width: 30%;
    margin: 10%;
    cursor: pointer;
  }

  img:hover {
    transform: scale(1.1);
    transition-duration: 0.2s;
  }
}

.language {
  cursor: pointer;
  width: 2.3em;
  margin: 0;
}

.arrow {
  cursor: pointer;
  width: 1.7em;
  margin-left: -0.4em;
}

.interactive-window {
  position: absolute;
  top: 110%;
  // left: -20%;
  // right: 10%;
  padding: 16px;
  z-index: 1000;
  width: 9em;
  border-bottom-right-radius: 1.5em;
}

//hamburger

.hamburger {
  display: none; /* Skryť hamburger na širších zariadeniach */
  cursor: pointer;
  z-index: 1500;
  margin-left: auto;

  .line {
    width: 25px;
    height: 3px;
    margin: 4px 0;
    transition: transform 0.3s ease-out;
  }
}

//režim

.toggle-switch {
  position: relative;
  margin: auto;
  width: 50px;
  height: 25px;
}

.toggle-switch input {
  display: none;
}

.toggle-switch label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  cursor: pointer;
  transition-duration: 0.3s;
}

.toggle-switch label::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  transition: left 0.3s;
}

.toggle-switch input:checked + label::after {
  left: 27px;
}

.menu-mobile,
.hamburger {
  display: none;
}

@media only screen and (max-width: 1300px) {
  .interactive-window {
    right: 5%;
  }
}
@media only screen and (max-width: 1100px) {
  .logo {
    font-size: 80%;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 950px) {
  .navigation ul {
    gap: 5%;
  }
}

@media only screen and (max-width: 850px) {
  .navigation ul {
    gap: 3%;
  }
}

@media only screen and (max-width: 750px) {
  header {
    .logo {
      width: 50%;
    }

    .navigation {
      width: 28%;
    }

    .control {
      width: 22%;
    }
  }

  .hamburger {
    display: block;
  }

  header {
    padding: 0 8%;
    box-sizing: border-box;
    gap: 8%;
  }

  .navigation,
  .language-switch {
    display: none;
  }

  .menu-mobile.open {
    position: absolute;
    display: block;
    top: 6em;
    width: 90%;
    margin: auto;
    line-height: 2.8em;
    border-bottom-right-radius: 1.5rem;

    li {
      width: 90%;
      margin: auto;
    }
  }
}
</style>
