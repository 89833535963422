<template>
  <section
    :class="{ 'dark-mode': $store.state.darkmode }"
    class="main-projects"
  >
    <div class="title">
      <h2>{{ texts[$store.state.language].references.title }}</h2>
    </div>
    <div class="projects">
      <main class="grid-item main">
        <div class="items">
          <div @click="openGallery(1)" class="item item1">
            <img
              src="@/assets/images/gallery/images1/0.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 1"
              class="gallery"
              src="@/assets/images/gallery/images1/1.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 1"
              class="gallery"
              src="@/assets/images/gallery/images1/2.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 1"
              class="gallery"
              src="@/assets/images/gallery/images1/3.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <h3>
              {{ texts[$store.state.language].references.project1.title }}
            </h3>
            <p class="popis">
              {{ texts[$store.state.language].references.project1.description }}
            </p>
          </div>
          <div @click="openGallery(2)" class="item item2">
            <img
              src="@/assets/images/gallery/images2/0.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 2"
              class="gallery"
              src="@/assets/images/gallery/images2/1.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 2"
              class="gallery"
              src="@/assets/images/gallery/images2/2.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 2"
              class="gallery"
              src="@/assets/images/gallery/images2/3.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <h3>
              {{ texts[$store.state.language].references.project2.title }}
            </h3>
            <p class="popis">
              {{ texts[$store.state.language].references.project2.description }}
            </p>
          </div>
          <div @click="openGallery(3)" class="item item4">
            <img
              src="@/assets/images/gallery/images3/0.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 3"
              class="gallery"
              src="@/assets/images/gallery/images3/1.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 3"
              class="gallery"
              src="@/assets/images/gallery/images3/2.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 3"
              class="gallery"
              src="@/assets/images/gallery/images3/3.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <h3>
              {{ texts[$store.state.language].references.project3.title }}
            </h3>
            <p class="popis">
              {{ texts[$store.state.language].references.project3.description }}
            </p>
          </div>
          <div @click="openGallery(4)" class="item item4">
            <img
              src="@/assets/images/gallery/images4/0.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 4"
              class="gallery"
              src="@/assets/images/gallery/images4/1.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 4"
              class="gallery"
              src="@/assets/images/gallery/images4/2.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 4"
              class="gallery"
              src="@/assets/images/gallery/images4/3.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <h3>
              {{ texts[$store.state.language].references.project4.title }}
            </h3>
            <p class="popis">
              {{ texts[$store.state.language].references.project4.description }}
            </p>
          </div>
          <div @click="openGallery(5)" class="item item5">
            <img
              src="@/assets/images/gallery/images5/0.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 5"
              class="gallery"
              src="@/assets/images/gallery/images5/1.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 5"
              class="gallery"
              src="@/assets/images/gallery/images5/2.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 5"
              class="gallery"
              src="@/assets/images/gallery/images5/3.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <h3>
              {{ texts[$store.state.language].references.project5.title }}
            </h3>
            <p class="popis">
              {{ texts[$store.state.language].references.project5.description }}
            </p>
          </div>
          <div @click="openGallery(6)" class="item item6">
            <img
              src="@/assets/images/gallery/images6/0.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 6"
              class="gallery"
              src="@/assets/images/gallery/images6/1.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 6"
              class="gallery"
              src="@/assets/images/gallery/images6/2.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 6"
              class="gallery"
              src="@/assets/images/gallery/images6/3.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <h3>
              {{ texts[$store.state.language].references.project6.title }}
            </h3>
            <p class="popis">
              {{ texts[$store.state.language].references.project6.description }}
            </p>
          </div>
          <div @click="openGallery(7)" class="item item7">
            <img
              src="@/assets/images/gallery/images7/0.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 7"
              class="gallery"
              src="@/assets/images/gallery/images7/1.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 7"
              class="gallery"
              src="@/assets/images/gallery/images7/2.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <img
              v-if="galleryOpen == 7"
              class="gallery"
              src="@/assets/images/gallery/images7/3.jpg"
              :alt="texts[$store.state.language].references.referenceImgAlt"
            />
            <h3>
              {{ texts[$store.state.language].references.project7.title }}
            </h3>
            <p class="popis">
              {{ texts[$store.state.language].references.project7.description }}
            </p>
          </div>
        </div>
      </main>
    </div>
  </section>
</template>

<script>
import { texts } from "@/assets/js/texts.js";

export default {
  mounted() {
    const slider = document.querySelector(".items");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });
  },
  data() {
    return {
      texts: texts,
      galleryOpen: 0,
    };
  },
  methods: {
    openGallery(int) {
      if (this.galleryOpen == int) {
        this.galleryOpen = 0;
      } else {
        this.galleryOpen = int;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";

//light mode
.items.active {
  background: rgba($color: $dt-clr-light, $alpha: 0.05);
}

section {
  background-color: $bg-clr-light;
}

h2,
h3 {
  color: $tx-clr-light;
}

.popis {
  color: $dt-clr-light;
}

//dark mode
.dark-mode .items.active {
  background: rgba($color: $dt-clr-dark, $alpha: 0.05);
}

.dark-mode {
  background-color: $bg-clr-dark;
}

.dark-mode h2,
.dark-mode h3 {
  color: $tx-clr-dark;
}

.dark-mode .popis {
  color: $dt-clr-dark;
  font-weight: 400;
}

//scss

h2 {
  padding: 1em 10% 0;
  font-weight: 400;
}

section {
  margin: 0 auto 5em;
}

//kód z luboskukla.sk posuvač

/* ten daťž */

@supports (display: grid) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr auto;

    //Let the craziness begin!!!
    grid-template-areas:
      "header header header"
      "title title footer"
      "main main main";
    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr;
      grid-template-rows: 0.3fr 1fr auto 1fr;
      grid-template-areas:
        "header"
        "title"
        "main"
        "footer";
    }
  }

  .grid-item {
    padding: 3.5em 1em;
    font-size: 1em;
    font-weight: 700;
  }

  .main {
    grid-area: main;
    padding: 0;
    overflow-x: hidden; //scroll
    overflow-y: hidden;
  }

  .items {
    position: relative;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;

    display: flex;
    // padding: 0 4%;
  }

  .items.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // pointer-events: none;
    margin: 2em 1em;
    box-sizing: border-box;

    max-width: 14rem;

    img {
      width: 14rem;
      height: 16rem;
      object-fit: cover;
      object-position: center;
    }

    h3 {
      text-align: left;
      font-weight: 400;
      font-size: 2.2em;
      margin: 0.5em 0 0.2em;
    }

    .popis {
      text-align: left;
      font-weight: 300;
      font-size: 1.4em;

      width: 80%;
      white-space: break-spaces;
    }
  }
}

.item .gallery {
  margin: 1em 0 0;
}

.item:first-child {
  margin-left: auto;
}

.item:last-child {
  margin-right: auto;
}

@media screen and (min-width: 1200px) {
  .item img {
    width: 20rem;
    height: 25rem;
  }
}

@media screen and (min-width: 800px) {
  .item img {
    width: 14rem;
    height: 16rem;
  }

  .item h3 {
    font-size: 1.8em;
  }

  .item .popis {
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 750px) {
  section {
    font-size: 80%;
  }

  h2 {
    padding: 0.5em 10% 0;
  }
}
</style>
