<template>
  <section :class="{ 'dark-mode': $store.state.darkmode }">
    <h1>{{ texts[$store.state.language].hero.title }}</h1>
    <div class="buttons">
      <button
        @click="$emit('scroll-to-section', 'services')"
        class="service-btn"
      >
        {{ texts[$store.state.language].hero.serviceBtn }}
      </button>
      <button
        @click="$emit('scroll-to-section', 'contact')"
        class="contact-btn"
      >
        {{ texts[$store.state.language].hero.contactBtn }}
      </button>
    </div>

    <div class="line">
      <img src="@/assets/images/line.png" />
    </div>
  </section>
</template>

<script>
import { texts } from "@/assets/js/texts.js";
export default {
  data() {
    return {
      texts: texts,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";

//farby pre light režim
section {
  background-color: $bg-sc-clr-light;
  background-image: url("@/assets/images/buildings.png");
}

h1 {
  text-shadow: 0.1em 0.1em 0 $bg-sc-clr-light;
  color: $tx-clr-light;
}

.service-btn {
  background-color: $tx-clr-light;
  color: $bg-sc-clr-light;
}

.contact-btn {
  background-color: $bg-sc-clr-light;
  color: $tx-clr-light;
  border: 0.18em solid $tx-clr-light;
}

//farby pre dark režim

.dark-mode {
  background-color: $bg-clr-dark;
  background-image: url("@/assets/images/buildingsNight.png");

  .service-btn {
    background-color: $tx-clr-dark;
    color: $bg-sc-clr-dark;
  }

  .contact-btn {
    background-color: $bg-sc-clr-dark;
    color: $tx-clr-dark;
    border: 0.18em solid $tx-clr-dark;
  }
  h1 {
    text-shadow: 0.1em 0.1em 0 $bg-sc-clr-dark;
    color: $tx-clr-dark;
  }
}

//scss
section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 90vh;
  text-align: center;
  background-size: cover;
  background-position: center;
}

.hero {
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  width: 10em;
  margin: 20vh 8vw 2vh;
  font-size: 3.5em;
  text-align: left;
  font-weight: 100;
}

.buttons {
  display: flex;
  gap: 1.4em;
  justify-content: center;
  margin-left: 8vw;
}

.buttons,
h1 {
  z-index: 10;
}

button {
  padding: 0.5em 1.1em;
  font-size: 1.3em;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.service-btn {
  border-bottom-left-radius: 0.9em;
}

.contact-btn {
  border-top-right-radius: 0.9em;
}

button:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
}

.line {
  position: absolute;
  bottom: 5vh;
  width: 100vw;
  left: -2vw;

  img {
    width: 90%;
    height: 95vh;
  }
}

@media only screen and (max-width: 1200px) {
  .line {
    bottom: 15vh;
  }
}

@media only screen and (max-width: 1000px) {
  .line {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  section {
    font-size: 2vw;
  }

  .buttons {
    font-size: 2.5vw;
  }
}

@media only screen and (max-width: 750px) {
  section {
    font-size: 2.5vw;
  }

  h1 {
    margin: 30vh 8vw 2vh;
  }

  .buttons {
    font-size: 3vw;
  }
}
</style>
