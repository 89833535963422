<template>
  <footer>
    <div class="main">
      <div class="logo">
        <img
          src="@/assets/images/logoBig.png"
          :alt="texts[$store.state.language].footer.logoAlt"
        />
        <p>
          {{ texts[$store.state.language].footer.descriptionPrefix }}
          <span>
            {{ texts[$store.state.language].footer.descriptionSpan1 }}
          </span>
          {{ texts[$store.state.language].footer.descriptionSeparator }}
          <span>
            {{ texts[$store.state.language].footer.descriptionSpan2 }}
          </span>
        </p>
      </div>
      <div class="navigation">
        <ul>
          <li @click="$emit('scroll-to-section', 'about')">
            {{ texts[$store.state.language].footer.navigation.about }}
          </li>
          <li @click="$emit('scroll-to-section', 'services')">
            {{ texts[$store.state.language].footer.navigation.services }}
          </li>
          <li @click="$emit('scroll-to-section', 'references')">
            {{ texts[$store.state.language].footer.navigation.references }}
          </li>
          <li @click="$emit('scroll-to-section', 'career')">
            {{ texts[$store.state.language].footer.navigation.career }}
          </li>
          <li @click="$emit('scroll-to-section', 'contact')">
            {{ texts[$store.state.language].footer.navigation.contact }}
          </li>
        </ul>
      </div>
      <div class="info">
        <p class="headline">
          {{ texts[$store.state.language].footer.companyAddress.headline }}
        </p>
        <div class="text">
          <p>{{ texts[$store.state.language].footer.companyAddress.name }}</p>
          <p>{{ texts[$store.state.language].footer.companyAddress.street }}</p>
          <p>{{ texts[$store.state.language].footer.companyAddress.city }}</p>
        </div>

        <div class="text-bold">
          <p>
            <span
              >{{ texts[$store.state.language].footer.companyAddress.icoLabel }}
            </span>
            {{ texts[$store.state.language].footer.companyAddress.ico }}
          </p>
          <p>
            <span
              >{{ texts[$store.state.language].footer.companyAddress.dicLabel }}
            </span>
            {{ texts[$store.state.language].footer.companyAddress.dic }}
          </p>
          <p>
            <span
              >{{
                texts[$store.state.language].footer.companyAddress.icdphLabel
              }}:
            </span>
            {{ texts[$store.state.language].footer.companyAddress.icdph }}
          </p>
        </div>
      </div>
      <div class="info">
        <p class="headline">
          {{ texts[$store.state.language].footer.operationalAddress.headline }}
        </p>
        <div class="text">
          <p>
            {{ texts[$store.state.language].footer.operationalAddress.name }}
          </p>
          <p>
            {{ texts[$store.state.language].footer.operationalAddress.street }}
          </p>
          <p>
            {{ texts[$store.state.language].footer.operationalAddress.city }}
          </p>
        </div>

        <div class="text-bold">
          <p>
            <span
              >{{
                texts[$store.state.language].footer.operationalAddress
                  .phoneLabel
              }}
            </span>
            {{ texts[$store.state.language].footer.operationalAddress.phone }}
          </p>
          <p>
            <span
              >{{
                texts[$store.state.language].footer.operationalAddress.webLabel
              }}
            </span>
            {{ texts[$store.state.language].footer.operationalAddress.web }}
          </p>
          <p>
            <span
              >{{
                texts[$store.state.language].footer.operationalAddress
                  .emailLabel
              }}
            </span>
            {{ texts[$store.state.language].footer.operationalAddress.email }}
          </p>
        </div>
      </div>
    </div>
    <aside>
      <div class="legal-links">
        <!-- <p>
          <a href="https://hsbuilding.eu/sk/GDPR">{{
            texts[$store.state.language].footer.legalLinks.terms
          }}</a>
        </p> -->
        <p>
          <router-link
            :to="'/sk/ochrana-osobnych-udajov'"
            @click="$store.state.language = 'sk'"
            >{{
              texts[$store.state.language].footer.legalLinks.privacy
            }}</router-link
          >
        </p>
      </div>
      <div class="social-links">
        <a
          href="https://www.facebook.com/profile.php?id=61561766341049&mibextid=LQQJ4d"
          target="_blank"
          ><img
            src="@/assets/images/facebook.svg"
            :alt="texts[$store.state.language].footer.socialLinks.facebook"
        /></a>
        <a
          href="https://www.instagram.com/h.s_building?igsh=MWR6bnZ3ZTllNWNhOA=="
          target="_blank"
          ><img
            src="@/assets/images/instagram.svg"
            :alt="texts[$store.state.language].footer.socialLinks.instagram"
        /></a>
      </div>
    </aside>
    <div class="small-footer">
      <p>
        {{ texts[$store.state.language].footer.smallFooter }}
      </p>
      <p>
        Web by <a href="https://luboskukla.sk" target="_blank"> Ľubos Kukla</a>
      </p>
    </div>
  </footer>
</template>

<script>
import { texts } from "@/assets/js/texts.js";
export default {
  data() {
    return {
      texts: texts,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  justify-content: space-around;
  padding: 4em 10% 0;
  box-sizing: border-box;

  .logo {
    width: 30%;
    box-sizing: border-box;
  }

  .navigation {
    width: 20%;
    box-sizing: border-box;
  }

  .info {
    width: 25%;
    box-sizing: border-box;
  }
}

.logo {
  img {
    width: 60%;
    max-width: 18em;
  }

  p {
    font-size: 1.2em;

    span {
      font-weight: 600;
    }
  }
}

.navigation {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 1em;

  ul {
    border-left: 0.1em solid #fff;
  }

  li {
    margin: 0 auto 0 0.5em;
    line-height: 1.4em;

    font-weight: 200;
    width: min-content;
    white-space: nowrap;
    font-size: 2em;
  }
}

.info {
  margin: auto 1em 1em;
  p {
    text-align: left;
    font-weight: 200;
    font-size: 1.1em;
  }

  .text {
    margin-bottom: 1em;
  }

  .headline {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0.8em;
  }

  .text-bold {
    span {
      font-weight: 600;
    }
  }
}

aside {
  margin: 1em 15% 2em;
  display: flex;
  justify-content: end;
  gap: 15%;

  .legal-links p {
    text-align: left;

    a {
      color: #fff;
      font-weight: 300;
    }
  }

  .social-links {
    display: flex;
    gap: 10%;

    img {
      width: 2.2em;
    }
  }
}

footer {
  background: #121212;

  p,
  li {
    color: #fff;
  }

  a {
    font-size: 1.1em;
    cursor: pointer;
    color: #5d7bbe;
  }

  .small-footer {
    padding: 1em;
    background: #000;

    p {
      font-size: 0.9em;
      font-weight: 200;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .main {
    padding: 4em 5% 0;
  }
}

@media only screen and (max-width: 1200px) {
  .main {
    padding: 4em 3% 0;
  }
}

@media only screen and (max-width: 950px) {
  .main {
    flex-direction: column;
    .logo,
    .navigation,
    .info {
      width: fit-content;
      margin: auto;
    }

    .info {
      width: 90%;
    }

    .logo p {
      max-width: 30em;
      padding: 0 5%;
    }

    .navigation {
      display: none;
    }

    .headline {
      margin-top: 2em;
    }

    .navigation {
      margin: 2em 1em;
    }
  }

  aside {
    justify-content: space-between;
    margin: 2em 10%;
    font-size: 80%;
  }
}
</style>
