<template>
  <section :class="{ 'dark-mode': $store.state.darkmode }">
    <div class="photos">
      <img
        src="@/assets/images/career1png.png"
        :alt="texts[$store.state.language].career.form.image1Alt"
      />
      <img
        src="@/assets/images/career2.png"
        :alt="texts[$store.state.language].career.form.image2Alt"
      />
    </div>
    <div class="content">
      <h2>{{ texts[$store.state.language].career.title }}</h2>
      <p class="popis">
        {{ texts[$store.state.language].career.description }}
        <a href="mailto:info@hsbuilding.eu">info@hsbuilding.eu </a>
        {{ texts[$store.state.language].career.description1 }}
      </p>

      <div class="formular">
        <form method="POST" action="https://hsbuilding.eu/php/sendMail.php">
          <label for="name"
            >{{ texts[$store.state.language].career.form.nameLabel }} *</label
          >
          <input
            required
            type="text"
            :placeholder="
              texts[$store.state.language].career.form.namePlaceholder
            "
            name="name"
            id="name"
            v-model="formData.name"
          />

          <label for="surname"
            >{{
              texts[$store.state.language].career.form.surnameLabel
            }}
            *</label
          >
          <input
            required
            type="text"
            :placeholder="
              texts[$store.state.language].career.form.surnamePlaceholder
            "
            name="surname"
            id="surname"
            v-model="formData.surname"
          />

          <label for="mail"
            >{{ texts[$store.state.language].career.form.mailLabel }} *</label
          >
          <input
            required
            type="text"
            :placeholder="
              texts[$store.state.language].career.form.mailPlaceholder
            "
            name="mail"
            id="mail"
            v-model="formData.mail"
          />

          <label for="tel">{{
            texts[$store.state.language].career.form.telLabel
          }}</label>
          <input
            type="text"
            :placeholder="
              texts[$store.state.language].career.form.telPlaceholder
            "
            name="tel"
            id="tel"
            v-model="formData.tel"
          />

          <label for="message">{{
            texts[$store.state.language].career.form.messageLabel
          }}</label>
          <textarea
            name="message"
            :placeholder="
              texts[$store.state.language].career.form.messagePlaceholder
            "
            id="message"
            cols="30"
            rows="4"
            v-model="formData.message"
          ></textarea>

          <div class="contact-method">
            <p>
              {{ texts[$store.state.language].career.form.contactMethodLabel }}
            </p>
            <label>
              <input type="radio" name="contact" value="Telefón" checked />
              {{
                texts[$store.state.language].career.form.contactMethodOptions
                  .phone
              }}
            </label>
            <label>
              <input type="radio" name="contact" value="Email" />
              {{
                texts[$store.state.language].career.form.contactMethodOptions
                  .email
              }}
            </label>
            <label>
              <input type="radio" name="contact" value="Nezáleží" />
              {{
                texts[$store.state.language].career.form.contactMethodOptions
                  .any
              }}
            </label>
          </div>

          <div class="privacy-policy">
            <label>
              <input
                required
                type="checkbox"
                name="privacy"
                v-model="formData.checked"
              />
              <p>
                {{
                  texts[$store.state.language].career.form.privacyPolicyLabel
                }}
                <a
                  href="https://hsbuilding.eu/sk/ochrana-osobnych-udajov.pdf"
                  >{{
                    texts[$store.state.language].career.form.privacyPolicy
                  }}</a
                >
              </p>
            </label>
          </div>

          <button type="submit" class="button">
            <a> {{ texts[$store.state.language].career.form.submitButton }} </a>
          </button>

          <p class="small">
            {{ texts[$store.state.language].career.form.smallText }}
          </p>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { texts } from "@/assets/js/texts.js";

export default {
  data() {
    return {
      formData: {},
      texts: texts,
    };
  },
  methods: {
    odoslanie() {
      this.formData = {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";

//light
section {
  background-color: $bg-clr-light;
}

.popis a {
  color: lighten($color: $tx-clr-light, $amount: 60%);
}

.popis {
  color: lighten($color: $tx-clr-light, $amount: 10%);
}

form {
  border: 0.1em solid darken($color: $dt1-clr-light, $amount: 10%);
}

::placeholder {
  color: $plc-clr-light;
}

input,
textarea {
  border: 0.1em solid $plc-clr-light;
}

.privacy-policy p {
  color: $plc-clr-light;
}

.small {
  color: $plc-clr-light;
}

button {
  background: $dt2-clr-light;
}

.privacy-policy input[type="checkbox"] {
  accent-color: $bg-sc-clr-light; /* Customize checkbox color */
}

.contact-method input[type="radio"] {
  margin-right: 0.5em;
  transform: scale(1.5);
  accent-color: $bg-sc-clr-light; /* Customize radio button color */
}

.contact-method input[type="radio"]:checked + span {
  color: $bg-sc-clr-light; /* Customize the color of the selected option */
}

.privacy-policy a,
.privacy-policy,
.contact-method label {
  color: darken($color: $dt-clr-light, $amount: 10%);
}

form > label,
input,
textarea {
  color: $tx-clr-light;
  background-color: $bg-clr-light;
}

.contact-method p {
  color: $tx-clr-light;
}

h2 {
  color: $tx-clr-light;
}

//dark
.dark-mode {
  background-color: $bg-clr-dark;
}

.dark-mode h2 {
  color: $tx-clr-dark;
}

.dark-mode .popis a {
  color: lighten($color: $tx-clr-dark, $amount: 60%);
}

.dark-mode .popis {
  color: lighten($color: $tx-clr-dark, $amount: 10%);
}

.dark-mode form {
  border: 0.1em solid darken($color: $dt1-clr-dark, $amount: 10%);
}

.dark-mode ::placeholder {
  color: $plc-clr-dark;
}

.dark-mode input,
.dark-mode textarea {
  border: 0.1em solid $plc-clr-dark;
}

.dark-mode .privacy-policy p {
  color: $plc-clr-dark;
}

.dark-mode .small {
  color: $plc-clr-dark;
}

.dark-mode button {
  background: $dt2-clr-dark;
}

.dark-mode .privacy-policy input[type="checkbox"] {
  accent-color: $bg-sc-clr-dark; /* Customize checkbox color */
}

.dark-mode .contact-method input[type="radio"] {
  accent-color: $bg-sc-clr-dark; /* Customize radio button color */
}

.dark-mode .contact-method input[type="radio"]:checked + span {
  color: $bg-sc-clr-dark; /* Customize the color of the selected option */
}

.dark-mode .privacy-policy a,
.dark-mode .privacy-policy,
.dark-mode .contact-method label {
  color: lighten($color: $dt-clr-dark, $amount: 25%);
}

.dark-mode form > label,
.dark-mode input,
.dark-mode textarea {
  color: $tx-clr-dark;
  background-color: $bg-clr-dark;
}

.dark-mode .contact-method p {
  color: $tx-clr-dark;
}

//scss
section {
  padding: 2em 10% 10em;
  display: flex;
  box-sizing: border-box;
  gap: 3%;
}

.photos {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.photos,
.content {
  width: 50%;
}

.photos img {
  width: 90%;
  margin-bottom: 3em;
}

h2 {
  text-align: center;
}

.popis {
  font-size: 1.5em;
  width: 95%;
  text-align: left;
  margin: 0 0 2em;

  a {
    text-decoration: underline;
  }
}

form {
  padding: 3em;
  border-top-right-radius: 1.5rem;

  display: flex;
  flex-direction: column;

  & > label {
    text-align: left;
    font-size: 1.1em;
    padding: 0.7em 0 0.5em;
  }

  ::placeholder {
    font-size: 1em;
  }

  input,
  textarea {
    padding: 0.5em 1.1em;
    font-size: 1.2em;
    text-align: left;
    border-radius: 0.3em;
  }

  textarea {
    resize: vertical;
    min-height: 2em;
    max-height: 10em;
  }
}

.contact-method {
  display: flex;
  flex-wrap: wrap;
}

.contact-method p {
  font-size: 1.2em;
  margin: 2em 0 1em;
  text-align: left;
  width: 100%;
}

//radio + GDPR
.contact-method label {
  display: inline-flex;
  align-items: center;
  margin-right: 1.4em;
  font-size: 1.1em;
  font-weight: normal;
}

.contact-method input[type="radio"] {
  margin-right: 0.5em;
  transform: scale(1.5);
}

.privacy-policy {
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.9em;
}

.privacy-policy label {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.privacy-policy input[type="checkbox"] {
  margin-right: 1.1em;
  font-size: 1.1em;
  transform: scale(1.3);
}

.privacy-policy a {
  text-decoration: underline;
}

.privacy-policy p {
  font-size: 1.1em;
  text-align: left;
}

.privacy-policy p a:hover {
  text-decoration: none;
}

button {
  border: none;
  padding: 1em;
  font-size: 1.2em;
  cursor: pointer;
  border-top-right-radius: 1.5em;
}

.small {
  font-size: 0.9em;
  text-align: left;
  width: 90%;

  margin-top: 2em;
}

@media only screen and (max-width: 1050px) {
  .photos {
    display: none;
  }

  .content {
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  section {
    font-size: 80%;
  }

  .popis {
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  section {
    font-size: 70%;
    padding: 1em 5% 5em;
  }

  form {
    padding: 3em 5%;
  }

  h2 {
    font-size: 5em;
  }

  .contact-method input[type="radio"],
  .privacy-policy input[type="checkbox"] {
    transform: scale(1);
    margin-right: 0;
  }
}
</style>
