<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$router.push("/");
  },
};
</script>

<style lang="scss" scoped></style>
