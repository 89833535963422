<template>
  <div class="page">
    <Header @scroll-to-section="scrollToSection"> </Header>
    <Hero @scroll-to-section="scrollToSection" id="hero" />
    <About id="about" />
    <Services id="services" />
    <References id="references" />
    <Career id="career" />
    <Footer @scroll-to-section="scrollToSection" id="contact" />
  </div>
</template>

<script>
import Header from "@/components/navigation/Header.vue";
import Hero from "@/components/pages/Hero.vue";
import About from "@/components/pages/About.vue";
import Services from "@/components/pages/Services.vue";
import References from "@/components/pages/References.vue";
import Career from "@/components/pages/Career.vue";
import Footer from "@/components/navigation/Footer.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    Hero,
    About,
    Services,
    References,
    Career,
    Footer,
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
