import { createRouter, createWebHistory } from "vue-router";
import SlovakPage from "../views/SlovakPage.vue";
import GermanPage from "../views/GermanPage.vue";
import NotFoundPage from "../views/NotFoundPage.vue";
import GDPR from "../views/GDPR.vue";

const routes = [
  {
    path: "/sk/",
    name: "SK",
    component: SlovakPage,
  },
  {
    path: "/de/",
    name: "DE",
    component: GermanPage,
  },
  {
    // 404 cesta, ktorá zobrazí NotFoundPage komponent
    path: "/:catchAll(.*)",
    component: NotFoundPage,
  },
  {
    path: "/sk/ochrana-osobnych-udajov",
    name: "GDPR",
    component: GDPR,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const language = navigator.language || navigator.userLanguage;
  const currentPath = to.path;

  // Ak URL neobsahuje '/sk' alebo '/de'
  if (!currentPath.startsWith('/sk') && !currentPath.startsWith('/de')) {
    if (language.includes('sk')) {
      next(`/sk${currentPath}`);
    } else {
      next(`/de${currentPath}`);
    }
  } else {
    next(); // Pokračuj na nasledujúcu cestu
  }
});

export default router;
